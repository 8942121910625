var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FormDrawer", {
    attrs: {
      loading: _vm.loading,
      width: 680,
      flat: "",
      title: "Home Information",
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "mDNS index", readonly: "" },
                              model: {
                                value: _vm.formData.mdnsName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "mdnsName", $$v)
                                },
                                expression: "formData.mdnsName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "IP Address", readonly: "" },
                              model: {
                                value: _vm.formData.IP,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "IP", $$v)
                                },
                                expression: "formData.IP",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "MAC", readonly: "" },
                              model: {
                                value: _vm.formData.MAC,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "MAC", $$v)
                                },
                                expression: "formData.MAC",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "CPU", readonly: "" },
                              model: {
                                value: _vm.formData.CPUType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "CPUType", $$v)
                                },
                                expression: "formData.CPUType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Revision", readonly: "" },
                              model: {
                                value: _vm.formData.DeviceInstanceRevision,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "DeviceInstanceRevision",
                                    $$v
                                  )
                                },
                                expression: "formData.DeviceInstanceRevision",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "EMB Flash", readonly: "" },
                              model: {
                                value: _vm.formData.EMBFlash,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "EMBFlash", $$v)
                                },
                                expression: "formData.EMBFlash",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Features", readonly: "" },
                              model: {
                                value: _vm.formData.DEVICEINSTANCEFeatures,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "DEVICEINSTANCEFeatures",
                                    $$v
                                  )
                                },
                                expression: "formData.DEVICEINSTANCEFeatures",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "Minimum Free Heap Size",
                                readonly: "",
                              },
                              model: {
                                value: _vm.formData.MinimumFreeHeapSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "MinimumFreeHeapSize",
                                    $$v
                                  )
                                },
                                expression: "formData.MinimumFreeHeapSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Free Heap Size", readonly: "" },
                              model: {
                                value: _vm.formData.FreeHeapSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "FreeHeapSize", $$v)
                                },
                                expression: "formData.FreeHeapSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "RTC current Time",
                                readonly: "",
                              },
                              model: {
                                value: _vm.formData.Time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "Time", $$v)
                                },
                                expression: "formData.Time",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Reset Reason", readonly: "" },
                              model: {
                                value: _vm.formData.ResetReason,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "ResetReason", $$v)
                                },
                                expression: "formData.ResetReason",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Compile Date", readonly: "" },
                              model: {
                                value: _vm.formData.CompileDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "CompileDate", $$v)
                                },
                                expression: "formData.CompileDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Compile Time", readonly: "" },
                              model: {
                                value: _vm.formData.CompileTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "CompileTime", $$v)
                                },
                                expression: "formData.CompileTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "IDF Version", readonly: "" },
                              model: {
                                value: _vm.formData.IDFVersion,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "IDFVersion", $$v)
                                },
                                expression: "formData.IDFVersion",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  (v) => !!v || "Please enter index",
                                  (v) =>
                                    (v && v.length <= 32) ||
                                    "Enter up to 32 characters",
                                ],
                                counter: "32",
                                label: "index",
                              },
                              model: {
                                value: _vm.formData.Name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "Name", _vm._n($$v))
                                },
                                expression: "formData.Name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  (v) => !!v || "Please enter Comments",
                                  (v) =>
                                    (v && v.length <= 120) ||
                                    "Enter up to 120 characters",
                                ],
                                counter: "120",
                                label: "Comments",
                              },
                              model: {
                                value: _vm.formData.comment,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "comment", $$v)
                                },
                                expression: "formData.comment",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c("v-spacer"),
            _c(
              "v-btn",
              { attrs: { text: "", "x-large": "" }, on: { click: _vm.close } },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "v-btn",
              {
                attrs: { text: "", type: "submit", "x-large": "" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" save ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }